import { TutorialType } from "../../hooks/query/useTutorial";
import { api, baseUrl } from "./api";

export const analytics = {
  productView(productId: string) {
    api.calls.post(`${baseUrl}/api/events/product`, {
      type: "productView",
      productId,
    });
  },
  productLike(productId: string) {
    api.calls.post(`${baseUrl}/api/events/product`, {
      type: "productLike",
      productId,
    });
  },
  tutorialDone(type: TutorialType) {
    api.calls.post(`${baseUrl}/api/events/onboarding`, {
      type: "tutorialDone",
      tutorial: type,
    });
  },
  productDislike(productId: string) {
    api.calls.post(`${baseUrl}/api/events/product`, {
      type: "productDislike",
      productId,
    });
  },
  search() {},
  feedClick() {},
  chatInit() {},
};
