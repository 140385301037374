import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { findIndex } from "lodash";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { MiniProduct } from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import appStyles from "../../App.module.scss";
import { Button } from "../../components/button/Button";
import { useHeaderActions } from "../../components/header/useHeaderActions";
import { SearchHeader } from "../../components/searchHeader/SearchHeader";
import { SlidingWindowData } from "../../components/slidingWindow/SlidingWindow";
import { ProductSlidingWindow } from "../../components/slidingWindow/types/product/ProductSlidingWindow";
import { useHomePage } from "../../hooks/query/home/useHomePage";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import { socketService } from "../../services/mock/socket";
import { ReactComponent as Arrow } from "../../styles/icons/ArrowRight.svg";
import { LookalikeWrapper } from "../Lookalike/LookalikeProducts";
import { FeedV2 } from "../feed/FeedV2";
import s from "./HomePage.module.scss";
import { Menu } from "./cmps/menu/Menu";
import { BrandsView } from "./views/brands/BrandsView";
import { CelebsView } from "./views/celebs/CelebsView";
import { PersonalFeed } from "./views/home/Feed";
import { RecentlyViewed } from "./views/home/RecentlyViewed";
import { SuggestedBrands } from "./views/home/SuggestedBrands";
import { Lookbooks } from "./views/home/lookbooks";
enum WindowNames {
  products = "products",
  menu = "menu",
}

export const HomePage: React.FC = () => {
  const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const [isSearch, setIsSearch] = useState(false);
  const { onSearch } = useHeaderActions();
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { data, isLoading } = useHomePage();
  const [selectedPage, setSelectedPage] = useState<Page>("HOME");
  useEffect(() => {
    if (isSearch) {
      searchRef.current?.focus();
    }
  }, [isSearch]);

  const onSearchHeader = (term: string) => {
    onSearch(term);
    navigate("/search");
  };

  return (
    <div className="column flex1">
      <SearchHeader
        onSearch={onSearchHeader}
        onBack={() => {}}
        isSearchMode={false}
        withLogo
      />

      <NavigationBar
        selectedPage={selectedPage}
        onSelectPage={(p) => setSelectedPage(p)}
      />
      <Views
        view={selectedPage}
        onOpenView={(view: Page) => setSelectedPage(view)}
      />
    </div>
  );
};

const Views: React.FC<{ view: Page; onOpenView: (v: Page) => void }> = ({
  view,
  onOpenView,
}) => {
  switch (view) {
    case "BRANDS":
      return <BrandsView />;
    case "CELEBS":
      return <CelebsView />;
    case "COLLECTIONS":
      break;
    case "FEED":
      return <FeedV2 />;
    case "HOME":
      return <HomeView onOpenView={onOpenView} />;

    default:
      return <></>;
  }
  return <></>;
};

const HomeView: React.FC<{ onOpenView: (p: Page) => void }> = ({
  onOpenView,
}) => {
  const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const { data, isLoading } = useHomePage();
  return (
    <div className="flex1 column">
      <div>
        <Swiper
          slidesPerView={1}
          className={appStyles.swiper}
          modules={[Pagination]}
          pagination={{ enabled: true }}
          spaceBetween={8}
        >
          <SwiperSlide>
            <LookalikeBanner />
          </SwiperSlide>
          <SwiperSlide>
            <EventBanner />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={s.feed}>
        <PersonalFeed
          products={data?.personalFeed.items ?? []}
          onClickProduct={(p) => {
            socketService.emit("windowOpen", "product", p);
          }}
          onOpenView={() => onOpenView("FEED")}
        />
        <Lookbooks
          onOpenView={() => onOpenView("CELEBS")}
          lookbooks={data?.lookbooks.items ?? []}
        />
        <SuggestedBrands
          onOpenView={() => onOpenView("BRANDS")}
          brands={data?.suggestedBrands.items ?? []}
        />
        <RecentlyViewed
          onClickProduct={(p) => socketService.emit("windowOpen", "product", p)}
          products={data?.recentlyViewed.items}
        />
      </div>
      <ProductSlidingWindow
        product={windowData?.data}
        isOpen={windowData?.name === WindowNames.products}
        onClose={() => setWindowData(null)}
        direction="right"
      />
      <Menu
        onClose={() => setWindowData(null)}
        isOpen={windowData?.name === WindowNames.menu}
      />
    </div>
  );
};

type Page =
  | "HOME"
  | "CELEBS"
  | "COLLECTIONS"
  | "BRANDS"
  | "FEED"
  | "FORMAL"
  | "OFFICE"
  | "CASUAL";
const PAGE_CODES: Page[] = [
  "HOME",
  "FEED",
  "CELEBS",
  "BRANDS",
  // "COLLECTIONS",
  // "FORMAL",
  // "OFFICE",
  // "CASUAL",
];

const NavigationBar: React.FC<{
  onSelectPage: (page: Page) => void;
  selectedPage: Page;
}> = ({ onSelectPage, selectedPage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperClass>();

  useEffect(() => {
    const idx = findIndex(PAGE_CODES, (p) => p === selectedPage);
    if (idx !== activeIndex) {
      setActiveIndex(idx);
      if (swiperRef.current) {
        swiperRef.current.slideToLoop(idx);
      }
    }
  }, [selectedPage]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.realIndex);
    onSelectPage(PAGE_CODES[swiper.realIndex]);
  };
  const handleSlideClick = (index: number) => {
    setActiveIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideToLoop(index);
    }
    onSelectPage(PAGE_CODES[index]);
  };
  return (
    <div>
      <div
        style={{ padding: "0px 16px  16px 16px" }}
        className="alignCenter gapLg "
      >
        {PAGE_CODES.map((t, index) => (
          <SwiperSlide
            style={{
              width: "auto",
              borderBottom: "3px solid transparent",
              borderColor: activeIndex === index ? "black" : "transparent",
            }}
            onClick={() => handleSlideClick(index)}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: activeIndex === index ? "black" : "#a5a5a5",
                marginBottom: "4px",
              }}
            >
              {t}
            </div>
          </SwiperSlide>
        ))}
        {/* </Swiper> */}
      </div>
    </div>
  );
};

const EventBanner = () => {
  const [callView] = useViewCaller();
  return (
    <HomeBackground>
      <div className={s.welcomeSection}>
        <h1
          style={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          HAVE AN EVENT? <br /> LET GIGI HELP YOU OUT
        </h1>
        <div className="justifyCenter">
          <Button
            onClick={() => callView({ id: "eventWizard", data: undefined })}
          >
            Find a dress
          </Button>
        </div>
      </div>
    </HomeBackground>
  );
};

const LookalikeBanner = () => {
  const [callView] = useViewCaller();
  return (
    <HomeBackground>
      <div className={s.welcomeSection}>
        <h1
          style={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          HAVE A STYLE IN MIND? <br />
          FIND LOOKALIKE PRODUCTS
        </h1>
        <div className="flex justifyCenter alignCenter">
          <div className="relative">
            <div>
              <Button onClick={() => {}}>
                <div className=" alignCenter gapMd">
                  <CameraAltOutlinedIcon sx={{ color: "white" }} />
                  Find my style
                </div>
              </Button>
            </div>
            <LookalikeWrapper
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <></>
            </LookalikeWrapper>
          </div>
        </div>
      </div>
    </HomeBackground>
  );
};

export const TrendingSearches: React.FC<{
  terms: string[];
  onClickProduct: (p: MiniProduct) => void;
}> = ({ terms, onClickProduct }) => {
  return (
    <div className={s.feedTitleContainer}>
      <div>
        <div className={s.feedTitle}>Trending Searches</div>
      </div>
      <Arrow />
    </div>
  );
};
export const HomeBackground: React.FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => {
  const { isMobile } = useContext(AppContext);

  return <div className={`${s.welcomeContainer}`}>{children}</div>;
};
