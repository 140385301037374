export const COLOR_MAP = {
  white: "white",
  pink: "pink",
  black: "black",
  blue: "#007BFF",
  brown: "#8B4513",
  "light blue": "lightblue",
  olive: "#808000",
  green: "#008000",
  lime: "#00FF00",
  coral: "#FF7F50",
  red: "#FF0000",
  gold: "#FFD700",
  lavender: "#E6E6FA",
  yellow: "#FFFF00",
  burgundy: "#800020",
  silver: "#C0C0C0",
  multi: "linear-gradient(45deg, #FF0080, #FF8C00, #40E0D0, #8A2BE2, #FF00FF)",
  beige: "#F5F5DC",
  "light pink": "#FFB6C1",
  navy: "#000080",
  magenta: "#FF00FF",
  teal: "#008080",
  orange: "#FFA500",
  grey: "#808080",
  purple: "#800080",
  fuchsia: "#FF00FF",
  aqua: "#00FFFF",
  mauve: "#E0B0FF",
  maroon: "#800000",
  ivory: "#FFFFF0",
  peachpuff: "#FFDAB9",
  "pastel green": "#77DD77",
  turquoise: "#40E0D0",
  "sage green": "#9DC183",
  "light green": "lightgreen",
  pewter: "#96A8A1",
  multicolor: "multi",
  "hot pink": "#FF69B4",
  "light grey": "#D3D3D3",
  peach: "#FFE5B4",
  "light brown": "#B5651D",
};

export const COLOR_NAMES = [
  "white",
  "beige",
  "yellow",
  "gold",
  "peach",
  "orange",
  "pink",
  "red",
  "burgundy",
  "maroon",
  "lavender",
  "purple",
  "blue",
  "light blue",
  "navy",
  "aqua",
  "turquoise",
  "green",
  "brown",
  "grey",
  "silver",
  "black",
  "multicolor",
];
