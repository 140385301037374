import { ColorValues } from "./colorValues/ColorValues";
import { LabelValues } from "./labelValues/LabelValues";
import { PriceRangeValues } from "./PriceRangeValues";

export const FilterCmpMap = {
  price: PriceRangeValues,
  main_color: ColorValues,
  length: LabelValues,
  pattern: LabelValues,
  silhouette: LabelValues,
  sleeve: LabelValues,
  back: LabelValues,
  bodice: LabelValues,
  hemline: LabelValues,
  lining: LabelValues,
  seasonality: LabelValues,
  transparency: LabelValues,
  neckline: LabelValues,
  fit: LabelValues,
  strap: LabelValues,
  skirt: LabelValues,
};
