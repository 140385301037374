import React, { ReactNode } from "react";
import s from "./Pill.module.scss";

export const Pill: React.FC<{
  children: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "secondary" | "backdrop";
}> = ({ children, size = "md", onClick, isSelected, type = "" }) => {
  return (
    <div
      onClick={onClick}
      className={`${s.pill} ${s[size]} ${isSelected ? s.selected : ""} ${
        s[type] ?? ""
      }`}
    >
      {children}
    </div>
  );
};
