import { Dictionary } from "@reduxjs/toolkit";
import { capitalize, isEmpty, keyBy } from "lodash";
import React, { useState } from "react";
import { FilterUnit } from "../../../@types/entities/product.types";
import { Pill } from "../../pill/Pill";
import { SwipeableWindow } from "../../slidingWindow/SwipeableWindow";
import { BASIC_PRODUCT_FILTERS } from "../Filters";
import { FilterCmpMap } from "../filterCmpMap/map";
import s from "./FiltersPreview.module.scss";

export const PREVIEW_FILTERS = ["price", ...BASIC_PRODUCT_FILTERS];
export const FiltersPreview: React.FC<{
  activeFilters: Dictionary<FilterUnit>;
  options: FilterUnit[];
  onChange: (key: string, value: any) => void;
}> = ({ activeFilters, options, onChange }) => {
  const [windowName, setWindow] = useState<string | null>(null);
  console.log({ windowName, options });

  const FilterPreview = !!windowName
    ? FilterCmpMap[windowName as keyof typeof FilterCmpMap]
    : null;
  return (
    <>
      {PREVIEW_FILTERS.map((filter) => {
        return (
          <div onClick={() => {}}>
            <Pill
              size="sm"
              onClick={() => setWindow(filter)}
              isSelected={!isEmpty(activeFilters[filter!]?.value)}
            >
              {capitalize(filter.replace("_", " "))}
            </Pill>
          </div>
        );
      })}
      <div
        // TODO: BUG, IT IS HIDING THE FILTER BUTTONS ON CLICK. THIS IS A WORKAROUND
        style={{ display: "none" }}
      >
        <SwipeableWindow
          // title={`Select ${windowName}`}
          onClose={() => setWindow(null)}
          isOpen={!!windowName && windowName !== "allFilters"}
          height="20%"
        >
          <>
            <div className={s.titleContainer}>
              <span className={s.title}>{windowName?.replace("_", " ")}</span>

              <div
                className={s.clear}
                onClick={() => onChange(windowName!, null)}
              >
                Clear all
              </div>
            </div>
            <div className={`${s.filterPreviewWrapper} no-swiping`}>
              {FilterPreview && (
                <div className="paddingLg flex1 flex">
                  <FilterPreview
                    onChange={(value) => onChange(windowName!, value)}
                    options={keyBy(options, "key")[windowName!].value}
                    selectedOptions={activeFilters[windowName!]?.value!}
                  />
                </div>
              )}
            </div>
          </>
        </SwipeableWindow>
      </div>
    </>
  );
};
