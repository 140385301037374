import { useQuery } from "react-query";
import {
  getLookbookDetails,
  getLookbooks,
  getUserFeed,
} from "../../../services/api/lookbook/lookbook.api";
import { getLookalikeProducts } from "../../../services/api/products/lookalike/lookalike.api";

export const useLookbookDetails = (id: string) => {
  return useQuery({
    queryKey: ["lookbook", id],
    queryFn: async () => {
      const res = await getLookbookDetails(id);
      return res;
    },
    enabled: !!id,
  });
};

export const useLookbooks = () => {
  return useQuery({
    queryKey: ["lookbooks"],
    queryFn: async () => {
      const res = await getLookbooks();
      return res;
    },
  });
};
export const useLookbooksFeed = () => {
  return useQuery({
    queryKey: ["lookbooksFeed"],
    queryFn: async () => {
      const res = await getUserFeed();
      return res;
    },
  });
};

export const useLookalikeProducts = (id?: string) => {
  return useQuery({
    queryKey: ["lookalike", id],
    enabled: !!id,
    queryFn: async () => {
      const res = await getLookalikeProducts(id!);
      return res;
    },
  });
};
