import { useParams } from "react-router";
import { useLookalikeProducts } from "../../hooks/query/lookbook/useLookbook";
import { BaseLookalikeProducts } from "./LookalikeProducts";

export const LookalikePage = () => {
  const { parseId } = useParams();
  const { data, isLoading } = useLookalikeProducts(parseId);

  return (
    <BaseLookalikeProducts
      products={data?.items ?? []}
      isLoading={isLoading}
      lookalikeImg={data?.lookalike?.path}
      onClickProduct={(p) => {
        window.open(p.vendors[0].url, "_blank");
      }}
    />
  );
};
