import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useSuggestedBrands } from "../../../../hooks/query/brands/useBrands";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { BrandPreview } from "../home/SuggestedBrands";
import s from "./BrandsView.module.scss";

export const BrandsView: React.FC = () => {
  const { data } = useSuggestedBrands({ limit: 25 });
  const { onOpen, onClose, isOpen, data: windowData } = useDelayedClose();
  const brands = useFlattenInfiniteQuery(data);
  const {
    data: productPages,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteProductSearch(
    { filters: [{ key: "brand", value: windowData?.data }], limit: 25 },
    !!windowData?.data
  );
  const products = useFlattenInfiniteQuery(productPages);

  return (
    <>
      <div className={s.container}>
        {brands.map((b, index) => (
          <div style={{ animationDelay: `${index / 30}s` }} className={s.brand}>
            <BrandPreview onOpen={onOpen} brand={b} />
          </div>
        ))}
      </div>
      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onClose()}
        title={windowData?.name}
        direction="right"
      >
        <div className="sideMargin">
          <ProductsGrid
            products={products}
            isLoading={isLoading}
            isFetchingNextPage={!!isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
          />
        </div>
      </SlidingWindow>
    </>
  );
};
