import { api, baseUrl } from "../../api";

export const parseLookalike = async (formData: FormData) => {
  try {
    const res = await api.calls.post(
      `${baseUrl}/api/products/lookalike/parse`,
      formData
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return { path: null, jobId: null };
  }
};

export const getLookalikeProducts = async (parseId: string) => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/products/lookalike?jobId=${parseId}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
