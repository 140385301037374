import { COLOR_MAP, COLOR_NAMES } from "../../consts";
import { FilterValuesProps } from "../types";
import s from "./ColorValues.module.scss";

export const ColorValues: React.FC<FilterValuesProps> = ({
  onChange,
  options,
  selectedOptions,
}) => {
  return (
    <div className={s.wrapperColors}>
      {COLOR_NAMES.map((option) => (
        <div className={s.container}>
          <div
            onClick={() => onChange(option)}
            className={`${s.colorValue} ${option === "multi" ? s.multi : ""} ${
              selectedOptions && selectedOptions.includes(option)
                ? s.selected
                : ""
            }`}
            style={{
              background: COLOR_MAP[option as keyof typeof COLOR_MAP] ?? "",
            }}
          />

          <span className={s.colorName}>{option}</span>
        </div>
      ))}
    </div>
  );
};
