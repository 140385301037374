import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrandSuggestion } from "../../../../@types/entities/brand";
import appStyles from "../../../../App.module.scss";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import s from "./HomeView.module.scss";

export const SuggestedBrands: React.FC<{
  brands: BrandSuggestion[];
  onOpenView: () => void;
}> = ({ brands, onOpenView }) => {
  // const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const { onOpen, onClose, isOpen, data: windowData } = useDelayedClose();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteProductSearch(
      { filters: [{ key: "brand", value: windowData?.data }], limit: 25 },
      !!windowData?.data
    );
  const products = useFlattenInfiniteQuery(data);
  return (
    <>
      <div>
        <div className={s.feedTitleContainer}>
          <div>
            <div className={s.feedTitle}>Brands for you</div>
          </div>
          <div onClick={onOpenView} className="alignCenter justifyCenter">
            <Arrow />
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          className={appStyles.swiper}
          modules={[Pagination]}
          pagination={{ enabled: true }}
          spaceBetween={8}
        >
          {brands.map((b) => (
            <SwiperSlide>
              <BrandPreview brand={b} onOpen={onOpen} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onClose()}
        title={windowData?.name}
        direction="right"
      >
        <div className="sideMargin">
          <ProductsGrid
            products={products}
            isLoading={isLoading}
            isFetchingNextPage={!!isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
          />
        </div>
      </SlidingWindow>
    </>
  );
};

export const BrandPreview: React.FC<{
  brand: BrandSuggestion;
  onOpen: (data: { name: string; data: string }) => void;
}> = ({ brand, onOpen }) => {
  return (
    <div
      onClick={() => onOpen({ name: brand.name, data: brand.name })}
      className="column gapLg"
    >
      <div style={{ position: "relative" }} className={s.brandProducts}>
        {brand.products.slice(0, 3).map((p) => (
          <div className="flex1">
            <img src={p.imgUrls[0]} alt="product" />
          </div>
        ))}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#00000055",
            color: "white",
            fontSize: "26px",
            textTransform: "uppercase",
            fontWeight: 500,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {brand.name}
        </div>
      </div>
      {/* <div className={s.brandBanner}>{b.name}</div> */}
    </div>
  );
};
