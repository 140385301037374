import { CircularProgress } from "@mui/material";
import React, {
  FocusEventHandler,
  ReactElement,
  forwardRef,
  useState,
} from "react";
import { ReactComponent as SearchIcon } from "../../styles/icons/Search.svg";
import styles from "./input.module.scss";

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange: (value: string, ev?: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  containerClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClear?: () => void;
  iconEnd?: ReactElement;
  weirdMobileBehaviorIconStartSearch?: boolean;
  placeholder?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onEnter?: (val: string) => void;
  theme?: "light" | "dark";
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      isLoading,
      disabled,
      containerClassName,
      iconEnd,
      placeholder,
      onBlur,
      onFocus,
      onEnter,
      weirdMobileBehaviorIconStartSearch,
      theme,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Enter" && onEnter) {
        onEnter(value);
      }
    };
    return (
      <div
        className={`${styles.container} ${
          isFocused ? styles.focused : ""
        } ${containerClassName}`}
      >
        {/* {IconStart && IconStart()} */}
        {weirdMobileBehaviorIconStartSearch && (
          <div className={`${styles.icon} ${value ? styles.active : ""}`}>
            <SearchIcon />
          </div>
        )}
        <input
          {...props}
          ref={ref}
          value={value}
          onChange={(ev) => onChange(ev.target.value, ev)}
          onKeyDown={onKeyDown}
          disabled={disabled}
          onFocus={(ev) => {
            setIsFocused(true);
            onFocus && onFocus(ev);
          }}
          onBlur={(ev) => {
            setIsFocused(false);
            onBlur && onBlur(ev);
          }}
          placeholder={placeholder ?? "YOU HAVE A BUG INSERT PLACEHOLDER"}
          className={styles[theme ?? ""]}
        />
        {iconEnd && iconEnd}
        {isLoading && <CircularProgress size={14} />}
        {/* TODO: LOADER  icons */}
      </div>
    );
  }
);
