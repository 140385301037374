import { ForwardedRef, forwardRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { socketService } from "../../services/mock/socket";
import { ReactComponent as Home } from "../../styles/icons/Home.svg";
import { ReactComponent as HomeActive } from "../../styles/icons/HomeActive.svg";
import { ReactComponent as Like } from "../../styles/icons/Like.svg";
import { ReactComponent as LikeActive } from "../../styles/icons/LikeActive.svg";
import { ReactComponent as Search } from "../../styles/icons/Search.svg";
import { ReactComponent as SearchActive } from "../../styles/icons/SearchActive.svg";
import { ReactComponent as Stars } from "../../styles/icons/ai.svg";
import { ReactComponent as User } from "../../styles/icons/user.svg";
import { useHeaderActions } from "../header/useHeaderActions";
import s from "./footer.module.scss";

export const Footer = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { onChat, isApp, isMobile } = useContext(AppContext);
  const { onSearch, searchTerm } = useHeaderActions();
  const navigate = useNavigate();
  const location = useLocation();

  const cleanup = () => {
    if (searchTerm) {
      onSearch("");
    }
    console.log("cleanup");

    socketService.emit("cleanup", "footer", {});
  };
  const onTapNav = (path: string) => {
    cleanup();
    navigate(path);
  };

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };
  return (
    <div
      ref={ref}
      className={`${s.footer}
      ${isApp || isMobile ? s.app : ""}
    `}
    >
      <div className={s.nav}>
        <div
          className={`${s.innerContainer}`}
          onTouchStart={() => onTapNav("/feed")}
        >
          <div className={`${s.icon} ${s.withFill}`}>
            {isActive("/feed") ? <HomeActive /> : <Home />}
          </div>
          {/* <span>Home</span> */}
        </div>
      </div>
      <div className={s.nav}>
        <div
          className={`${s.innerContainer} `}
          onTouchStart={() => onTapNav("/search")}
        >
          <div className={`${s.icon}`}>
            {isActive("/search") ? <SearchActive /> : <Search />}
          </div>
          {/* <span>Search</span> */}
        </div>
      </div>
      <div className={s.relativeController}>
        <div
          className={s.chatBtn}
          onTouchStart={() => {
            onChat();
          }}
        >
          <div className={s.gradient}>
            <div className={s.bgBtn}>
              <Stars />
            </div>
          </div>
        </div>
      </div>
      {/* <div className={s.nav} onTouchStart={onChat}>
        <div className={s.innerContainer}>
          <div className={`${s.icon} ${s.withColor}`}>
            <Chat />
          </div>
          <span>Explore</span>
      </div>
      </div> */}
      <div className={s.nav}>
        <div
          className={`${s.innerContainer} ${
            isActive("/lists") ? s.active : ""
          }`}
          onTouchStart={() => onTapNav("/lists")}
        >
          <div className={s.innerContainer}>
            <div className={`${s.icon} ${s.withFill}`}>
              {isActive("/lists") ? <LikeActive /> : <Like />}
            </div>
            {/* <span>Lists</span> */}
          </div>
        </div>
      </div>
      <div className={s.nav}>
        <div
          className={`${s.innerContainer} ${
            isActive("/profile") ? s.active : ""
          }`}
          onTouchStart={() => onTapNav("/profile")}
        >
          <div className={s.innerContainer}>
            <div className={`${s.icon} ${s.withFill}`}>
              <User />
            </div>
            {/* <span>profile</span> */}
          </div>
        </div>
      </div>
    </div>
  );
});
