import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import {
  Lookbook,
  LookbookDetailsPage,
} from "../../../lookbook/LookbookDetails/LookbookDetails";
import s from "./HomeView.module.scss";

export const Lookbooks: React.FC<{
  lookbooks: Lookbook[];
  onOpenView: () => void;
}> = ({ lookbooks, onOpenView }) => {
  const { onOpen, onClose, isOpen, data } = useDelayedClose();
  return (
    <>
      <div>
        <div className={s.feedTitleContainer}>
          <div className={s.feedTitle}>Inspo from the stars</div>
          <div className="alignCenter justifyCenter" onClick={onOpenView}>
            <Arrow />
          </div>
        </div>
        <div style={{ alignItems: "flex-start" }} className={s.celebThumbnails}>
          {lookbooks.length && (
            <>
              {lookbooks.slice(0, 6).map((l) => (
                <CelebThumbnail lookbook={l} onOpen={onOpen} />
              ))}
              <div
                onClick={() => onOpenView()}
                style={{
                  borderRadius: "50%",
                  border: "thin solid #00000010",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "18px",
                  fontWeight: 500,
                  minWidth: "84px",
                  minHeight: "84px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                See more
              </div>
            </>
          )}
        </div>
      </div>
      <SlidingWindow
        onClose={() => onClose()}
        direction="right"
        title={""}
        isOpen={isOpen && !!data}
        keepMounted={false}
      >
        <LookbookDetailsPage lookbook={data!} />
      </SlidingWindow>
    </>
  );
};

export const CelebThumbnail: React.FC<{
  onOpen: (lookbook: Lookbook) => void;
  lookbook: Lookbook;
}> = ({ onOpen, lookbook }) => {
  return (
    <div onClick={() => onOpen(lookbook)} className={s.celebrityAvatar}>
      <div className={s.bgGradient}>
        <div className={s.imgContainer}>
          <img
            src={lookbook.thumbnail}
            alt="celebrity"
            style={{
              borderRadius: "50%",
              objectFit: "cover",
              aspectRatio: 1 / 1,
            }}
          />
        </div>
      </div>
      <span style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
        {lookbook.name}
      </span>
    </div>
  );
};
