import { cloneDeep, findIndex, isEmpty } from "lodash";
import { FilterUnit } from "../../@types/entities/product.types";
import { toggleInList } from "../../utils/array";

export const FilterService = {
  updateSingleValue: (
    originFilters: FilterUnit[],
    key: string,
    value: string
  ) => {
    const filters = cloneDeep(originFilters);
    const index = findIndex(filters, ({ key: keyName }) => keyName === key);
    if (index === -1) {
      filters.push({ key, value: [value] });
      return filters;
    }
    const values = filters[index].value;
    filters[index].value = toggleInList(values, value);
    if (isEmpty(filters[index].value)) {
      filters.splice(index, 1);
    }
    return filters;
  },
  replaceFilterValue: (
    originFilters: FilterUnit[],
    key: string,
    value: any
  ) => {
    const filters = cloneDeep(originFilters);
    const index = findIndex(filters, ({ key: keyName }) => keyName === key);
    if (index === -1) {
      filters.push({ key, value });
      return filters;
    }
    if (!!value || !isEmpty(value)) {
      filters[index].value = value;
    } else {
      filters.splice(index);
    }
    return filters;
  },
};
